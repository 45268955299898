import React from "react";
import { Link } from "react-router-dom";

import ucl from './pics/ucl.png';
import brown from './pics/brown2.png';
import figma from './pics/figma.png';
import docker from './pics/docker.png';
import kubernetes from './pics/kubernetes.png';
import python from './pics/python.png';
import java from './pics/java.png';
import react from './pics/react.png';
import adobe from './pics/adobe.png';
import git from './pics/git.png';
import jira from './pics/jira.png';
import miro from './pics/miro.png';
import risd from './pics/risd2.png';
import amazon from './pics/aws.png';
import jup from './pics/jup.png';
import me2 from './pics/me3.png';

function About() {
  return (
    <div>
      <div className="flex flex-col min-h-screen">
        <div className="flex pt-2 px-4 py-2 justify-between items-center">
          <div className="mr-auto">
            <span className="grow text-black-100 font-bold text-xl hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-700">
              <a href="/">Dylan Garcia</a>
            </span>
          </div>
          <div className="px-0 text-black-100 text-l hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-700">
            <Link to="/#portfolio">Portfolio</Link>
          </div>
          <div className="px-2 text-black-100 text-l hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-700">
            <a href="/CV">CV</a>
          </div>
          <div className="text-black-100 text-l hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-700">
            <a href="/About">About</a>
          </div>
        </div>

        {/* ... keep the rest of your content below here exactly the same ... */}
        {/* You can keep your sections and image grids unchanged */}

      </div>
    </div>
  );
  
}

export default About;

