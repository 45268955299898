import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from "./home";
import About from "./about";
import { initGA, logPageView } from "./analytics"; 

function AnalyticsListener() {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  return null;
}

function App() {
  useEffect(() => {
    initGA(); 
  }, []);

  return (
    <Router>
      <AnalyticsListener />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/CV" element={<Home />} />
        <Route path="/Projects" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;

