// src/analytics.js
import ReactGA from "react-ga4";

// Replace with your actual GA Measurement ID
const MEASUREMENT_ID = "G-10F7QNQDQN";

export const initGA = () => {
  ReactGA.initialize(MEASUREMENT_ID);
};

export const logPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};
